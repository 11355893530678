.proposal-main-container {    
    min-height: 100vh;
    background: linear-gradient(to bottom, #ecf3f9 20vh, lightblue 20vh, lightblue 80%, #ecf3f9 80%);
    /*background: linear-gradient(to bottom, #ecf3f9 20vh, lightblue 20vh,  lightblue 40vh #ecf3f9 24vh);
    */
    display: flex;
    justify-content: center;   
    padding-top: 10vh; 
    padding-bottom: 10vh; 
  }

  .proposal-container-main-header {
    color:#fff;
    font-weight: 900;
    font-family: 'Montserrat', sans-serif;
    font-size: 2.9rem;    
    text-align: center;
    margin-top: 6vh;
  }
  .proposal-sub-container {
      width: 45vw;
      background: #fff;     
      min-height: 80vh;
      box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,.1), 0 0.9375rem 1.40625rem rgba(90,97,105,.1), 0 0.25rem 0.53125rem rgba(90,97,105,.12), 0 0.125rem 0.1875rem rgba(90,97,105,.1);
  }
  .header-container {
    background-image: linear-gradient(to bottom,rgba(173, 216, 230, .5) 65%, rgb(173, 216, 230) 100%), url(https://images.unsplash.com/photo-1445991842772-097fea258e7b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80);
    background-size: cover;
    height: 20vh;
    margin: 80px;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .header-container-text {

    display: flex;
    justify-content: center;
    align-content: center;
  }
  .header-container-text-container {
    display: flex;
    justify-content: center;
    align-content: center;
  }


  .proposal-info-container {
    margin-top: 10vh;
    padding: 80px;
    padding-top: 0;   
    padding-bottom: 0;  
  }
  .proposal-info-item-container {
      display: flex;
  }
  .proposal-info-item-header {
    font-weight: 900;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;    
    margin-bottom: 0;
    width: 200px;
  }
  .proposal-info-item-text {
    margin-bottom: 30px;
    margin-top: 0px;
    margin-left: 20px;
    font-family: 'Open Sans', sans-serif !important;
}





  .price-container {
      padding: 80px;
      padding-top: 0;   
      margin-top: 50px;   
  }

  .price-container-header {
    color:lightblue;
    font-weight: 900;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem;
  }
  .price-table {
    font-family: 'Open Sans', sans-serif !important;
  }
  .price-table th {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 10px;
  }

  .footer-container {
      margin-top: 20vh;    
      margin-bottom: 40px;      
      padding-top: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;      
  }
  .footer-container-header {
    color:lightblue;
    font-weight: 900;    
    font-family: 'Montserrat', sans-serif;
    font-size: 1.9rem;    
  }
  .footer-company-info-container {
      display: flex;
      width: 100%;
      padding: 120px;
      padding-top: 0;
      margin-top: 20px;
      padding-bottom: 0;
      flex-direction: row;
      justify-content: space-between;
  }
  .footer-container-company-info-text {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 300;
    font-size: 12px;
  }
  .price-table-container {
    display: none;
  }

  .proposal-item-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    width: 100%;
    padding: 80px;
    padding-top: 0;      
  }
  .proposal-item-sub-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: #a9a9a9 1px dashed;
    margin-bottom: 20px;
    padding-bottom: 5px;
    text-align: right;
  }
  .proposal-item-header {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 12px;
    margin-bottom: 0;
  }
  .proposal-item-text {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 0;
  }
  .rovaniemi-feature-service-item-container-text-header {
    text-align: center;    
  }
  .rovaniemi-feature-service-item-container-text-sub-sub {
    margin-bottom: 10px;
    font-weight: 300;
    font-family: 'Montserrat', sans-serif;
  }

@media only screen and (max-width: 600px) { 
    .price-container {
        width: 90vw;
        padding: 2vh;
      padding-top: 0;
    }
    .proposal-sub-container {
        width: 90vw;        
    }
    .header-container {
        height: 20vh;
        margin: 5vw;        
    }
    .footer-container-header {
        font-size: 1rem;
    }
    .price-table-container {
      display: none;
    }
    .footer-company-info-container {
        display: flex;
        width: 100%;
        padding: 5vw;
        padding-top: 0;
        padding-bottom: 0;
        flex-direction: row;
        justify-content: space-between;
    } 
    .proposal-info-container {
        width: 90vw;
        padding: 2vh;
      padding-top: 0;
    }
    .proposal-info-item-header {
        font-size: 0.7rem;
        width: 150px;
    }
    .proposal-item-container {
        padding: 20px;
    } 
    .footer-company-info-container {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center; 
    }
}

