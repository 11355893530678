/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFW50bbck.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0b.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gfD_u50.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

html {
  scroll-behavior: smooth;
  font-display: fallback !important;
}
.rovaniemi-alert {
  margin-bottom: 0 !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}
.rovaniemi-main-container {
  font-family: 'Montserrat', sans-serif;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
.rovaniemi-top-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  /* background: lightblue; 
  background: -webkit-linear-gradient(lightblue 15%, lightblue 100%);
  background: -o-linear-gradient(lightblue 15%, lightblue 100%);
  background: -moz-linear-gradient(#add8e6 15%, lightblue 100%);
  background: linear-gradient(lightblue 15%, lightblue 100%); */
  width: 100%;
  padding: 20px;
  padding-top: 50px;
  min-height: 80vh;  
  background-size: cover;
  
   /* background: linear-gradient(-110deg, #ace5ee  60%, #add8e6 60%);*/
  /* background: linear-gradient(60deg, lightblue 55%, rgba(0, 0, 0, 0.4) 50%),url(https://images.unsplash.com/photo-1445991842772-097fea258e7b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80) no-repeat center right;*/


  background-image: linear-gradient(to bottom,rgba(98, 196, 228, 0.7) 65%, rgb(173, 216, 230) 100%), url('../images/bg.jpg');
  background-position: center;
  background-color: #add8e6; 
}

.rovaniemi-top-container-phone-container {
  flex: 0.5;
  display: flex;
  flex-direction: column;  
  justify-content: center;
  align-items: flex-end;
}
.every-day-bold {
  font-weight: 900;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
}
.rovaniemi-top-container-phone-text-container {
  flex: 0.8;
  display: flex;
  flex-direction: column;  
  justify-content: center;
  align-items: flex-start;
}
.rovaniemi-top-container-phone-image {
  width: 70%;  
}
.rovaniemi-top-container-phone-text-header {
  font-weight: 700;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
}
.rovaniemi-top-container-phone-text-sub {
  color: #fff;  
  font-size: 28px;
  margin-top: 20px;
}
.rovaniemi-top-container-you-text {
  color: #fff;  
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 50px;
}

.rovaniemi-beta-login-or-container {
  display: flex;
  background: red;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.rovaniemi-beta-login-or-text {
  margin: 0;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  color: rgba(255, 255, 255, 0.8);
}
.rovaniemi-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;  
}
.rovaniemi-beta-login-or-text-container {
  margin-left: 35px;
  margin-right: 35px;
}
.rovaniemi-beta-button-text {
  font-family: 'Montserrat', sans-serif;
}
.rovaniemi-beta-button-container {
  background-color: #fff;
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;  
  transition: 400ms all ease-in-out;
  
}
.rovaniemi-beta-button-container:hover {  
  
}
.rovaniemi-beta-button-text {
  margin: 0;
  font-size: 12px;  
  font-family: 'Montserrat', sans-serif;
}

.rovaniemi-login-container {
  display: flex;
  align-items: center;
  height: 100%;
}
.rovaniemi-login-button-text {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}

.rovaniemi-feature-container {
  display: flex;
  flex-direction: row;
  padding-top: 100px;
  padding-bottom: 100px;

}
.rovaniemi-call-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-top: 15px;
  padding-bottom: 15px; 
  background-color: #efefef;
}
.rovaniemi-feature-container-workspace-container {
  flex: 0.5;
  display: flex;
  flex-direction: column;  
  justify-content: center;  
  align-items: center;  
}
.rovaniemi-feature-container-workspace-text-container {
  flex: 1;
  display: flex;
  flex-direction: column;  
  justify-content: center;
  align-items: center;
}
.rovaniemi-feature-container-about {
  display: flex;
  flex: 1;
  flex-direction: column;  
  justify-content: center;
  align-items: center;
}
.rovaniemi-feature-container-call {
  display: flex;
  flex: 0.8;
  flex-direction: row;  
  justify-content: space-between;
  align-items: center;  
}
.rovaniemi-feature-container-call-text {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  font-size: 16px;  
  color: #434343;
  
}
.rovaniemi-call-container-input-container {
  display: flex;
  flex-direction: row;
}
.rovaniemi-call-container-input-field {
  margin-right: 10px;
  border-radius: 30px !important;
  font-family: 'Open Sans', sans-serif;
  color: #434343 !important;
}



.rovaniemi-feature-container-workspace-image {
  width: 50%;  
  margin-left: 50px; 
}
.rovaniemi-feature-container-workspace-text-header {
  font-weight: 700;
  color: #434343;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  width: 60%;
}
.rovaniemi-feature-container-workspace-text-sub {
  color: #434343;  
  font-family: 'Open Sans', sans-serif;
  width: 60%;
  text-align: center;
  margin-bottom: 70px;
}

.checkout-container {
  margin-top: 50px;
}
.rovaniemi-create-label.card-label {
  margin-top: 50px;
}
.rovaniemi-test-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #f7f7f7
}
.rovaniemi-test-container-text-header {
  font-weight: 700;
  color: #434343;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  width: 60%;
}
.rovaniemi-test-container-use {
  display: flex;
  flex-direction: row;
  flex: 1;  
  width: 80%;
  margin-top: 100px;
  margin-bottom: 100px;
}
.rovaniemi-test-container-sliders {  
  display: flex;
  flex-direction: column;  
  flex: 0.4;
  padding-left: 200px;
}
.rovaniemi-test-container-sum {
  display: flex;
  flex: 0.5;
  justify-content: center;
  align-items: center;
}
.rovaniemi-test-container-sum-text {
  font-weight: 700;
  color: lightblue;
  font-size: 3.9rem;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}
.noUi-connect {
  background: lightblue !important;  
}
.rovaniemi-slider-header {
  margin-top: 50px;
  margin-bottom: 0;
}


.checkout {
  
  padding: .8125rem .8125rem;
  border: 1px solid #e1e5eb;
  font-size: .875rem;
    line-height: 1.5;
    font-family: 'Open Sans', sans-serif !important;
    border-radius: .375rem;
}

.rovaniemi-feature-container-workspace-text-sub.strong{
  font-weight: 700;
  color: #434343;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.rovaniemi-phone-number-container {
  margin-top: 40px;
  margin-bottom: 40px;
}
.rovaniemi-phone-number-text {
  color: #fff;
  font-size: 38px;
  margin: 0;
}
.rovaniemi-phone-number-text-sub {
  color: #fff;
  font-size: 12px;
}
.rovaniemi-feature-container.dark{
  background-color: rgba(194, 194, 194, 0.09);
}

.rovaniemi-feature-container-workspace-text-container-opposite {
  flex: 0.5;
  display: flex;
  flex-direction: column;  
  justify-content: center;  
}
.rovaniemi-feature-container-workspace-container-opposite {
  flex: 0.5;
  display: flex;
  flex-direction: column;  
  justify-content: center;  
  align-items: center;  
}



.rovaniemi-bottom-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;  
  background: lightblue; /* For browsers that don't support gradients */
  background: -webkit-linear-gradient(lightblue 15%, lightblue 100%);
  background: -o-linear-gradient(lightblue 15%, lightblue 100%);
  background: -moz-linear-gradient(lightblue 15%, lightblue 100%);
  background: linear-gradient(lightblue 15%, lightblue 100%);
  width: 100%;
  padding: 20px; 
}
.rovaniemi-bottom-container-text-header {
  font-weight: 700;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  text-align: center;  
  margin-bottom: 50px;
}

.rovaniemi-request-label {
  font-family: 'Open Sans', sans-serif !important;
  color: #434343;
}
.rovaniemi-request-header{
  width: 100%;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 50px;
  color: #434343;
}
.rovaniemi-request-input {
  font-family: 'Open Sans', sans-serif;
}
.rovaniemi-request-button-container {
  width: 100%;
  display: flex;
  justify-content: center;  
}
.rovaniemi-request-button {
  margin-top: 50px;
  width: 150px;
  font-family: 'Montserrat', sans-serif !important;
}

.rovaniemi-call-button-text {
  margin: 0;
  font-size: 12px;  
  color: #434343 !important;
}


.rovaniemi-create-label {
  font-family: 'Open Sans', sans-serif !important;
  color: #999;
}
.rovaniemi-create-header{
  width: 100%;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 50px;
  color: #434343;
}
.rovaniemi-create-input {
  font-family: 'Open Sans', sans-serif;
}
.rovaniemi-create-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.rovaniemi-create-button {
  margin-top: 50px;
  padding-left: 10px;
  padding-right: 10px; 
  font-family: 'Montserrat', sans-serif !important;
}


/*Plans styles*/
.rovaniemi-plans-container.dark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 100px;
  background-color: rgba(194, 194, 194, 0.09);
}
.rovaniemi-plans-header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rovaniemi-plans-header-container-main-header {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  color: #434343;
}
.rovaniemi-plans-header-container-sub-header {
  color: #434343;  
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  margin: 0;
}

.rovaniemi-plans-plans-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.rovaniemi-plans-plan-container {
  background-color: #fff;
  width: 400px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;  
  padding-top: 50px;
  padding-bottom: 50px;    
  margin-top: 30px;
  color: #434343;  
}

.rovaniemi-plans-plan-container.recommended {
  background: -webkit-linear-gradient(lightblue 15%, lightblue 100%);
  color: #fff !important;
  border-radius: 10px;
  padding-top: 100px;
  padding-bottom: 100px;
  z-index: 30;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,.1), 0 0.9375rem 1.40625rem rgba(90,97,105,.1), 0 0.25rem 0.53125rem rgba(90,97,105,.12), 0 0.125rem 0.1875rem rgba(90,97,105,.1);
}

.rovaniemi-plan-name-text {
  font-family: 'Montserrat', sans-serif;  
  font-size: 0.953rem;
}


.rovaniemi-plan-price-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.rovaniemi-plan-price-number {
  font-size: 2.953rem;
  margin-left: 5px;
  margin-right: 5px;
}
.rovaniemi-plan-description-container {
  width: 100%;
}
.rovaniemi-plan-price-description-text {
  font-family: 'Open Sans', sans-serif;
  padding-left: 10%;
  padding-right: 10%;
  margin: 0;  
  display: flex;
  justify-content: center;
}

.rovaniemi-plan-price-container {
  display: flex;
  flex-direction: row;
}


.rovaniemi-plan-button {
  border: 1px solid #434343;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 150px;
  height: 50px;
  border-radius: 30px;
  cursor: pointer;  
}

.rovaniemi-plan-button-container {
  margin-top: 50px;
}
.rovaniemi-plan-button.recommended {
  border: 1px solid #fff;
  background-color: #fff;
  color: lightblue;
  animation: pulse 3s infinite;
}
.rovaniemi-plan-button.recommended:hover {  
  animation: pulse 1.5s infinite;
}

.rovaniemi-prop-list-item-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  
  color: #fff;
}
.rovaniemi-prop-list-item-text {
  
  margin-left: 5px;
}

.rovaniemi-feedback-container-text-header {

  font-weight: 700;
  color: #434343;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  width: 60%;
}
.rovaniemi-feedbacks-container {
  margin-top: 50px;
}
.rovaniemi-feedback-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.rovaniemi-feedback-avatar {
  height: 50px;
  width: 50px;
  margin-left: 150px;
  margin-right: 20px;
  background-color: lightblue;
  border-radius: 50%;
  border: #fff solid 0px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,.1), 0 0.9375rem 1.40625rem rgba(90,97,105,.1), 0 0.25rem 0.53125rem rgba(90,97,105,.12), 0 0.125rem 0.1875rem rgba(90,97,105,.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.rovaniemi-feedback-avatar-right {
  height: 50px;
  width: 50px;
  margin-left: 20px;
  margin-right: 100px;
  background-color: lightblue;
  border-radius: 50%;
  border: #fff solid 0px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,.1), 0 0.9375rem 1.40625rem rgba(90,97,105,.1), 0 0.25rem 0.53125rem rgba(90,97,105,.12), 0 0.125rem 0.1875rem rgba(90,97,105,.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.rovaniemi-feedback-avatar-second {
  height: 50px;
  width: 50px;
  margin-left: 60px;
  margin-right: 20px;
  background-color: lightblue;
  border-radius: 50%;
  border: #fff solid 0px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,.1), 0 0.9375rem 1.40625rem rgba(90,97,105,.1), 0 0.25rem 0.53125rem rgba(90,97,105,.12), 0 0.125rem 0.1875rem rgba(90,97,105,.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.rovaniemi-feedback-avatar-text {
  margin: 0;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
}
.rovaniemi-feedback-text-container {
  background-color: #fff;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: #434343 solid 0px;
  border-radius: 5px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,.01), 0 0.9575rem 1.40625rem rgba(90,97,105,.01), 0 0.25rem 0.53125rem rgba(90,97,105,.05), 0 0.125rem 0.1875rem rgba(90,97,105,.05);
}
.rovaniemi-feedback-text-container-text {
  margin: 0;
  color: #434343;
}


.rovaniemi-service-container-text-header {
  font-weight: 700;
  color: #274E5B;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}
.rovaniemi-service-container {
  display: flex;
  flex: 1;  
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 50vh;
}
.rovaniemi-service-items-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}



.rovaniemi-feature-service-item-container {
  width: 30vw;
  height: 400px;
  margin: 10px;
  padding: 40px;
  padding-bottom: 0px;
  display: flex;
  justify-content: center;
  background-color: rgb(98, 196, 228);
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  border: transparent solid 4px;
  box-shadow: rgba(0, 5, 82, 0.08) 0px 7px 14px 0px;
  /*box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,.1), 0 0.9375rem 1.40625rem rgba(90,97,105,.1), 0 0.25rem 0.53125rem rgba(90,97,105,.12), 0 0.125rem 0.1875rem rgba(90,97,105,.1);
*/
  background-color: #fff;
}


.rovaniemi-feature-service-item-container-text-header {
  color: #274E5B;
  font-family: 'Montserrat', sans-serif;  
  font-size: 1.953rem;
  height: 100px;
  text-align: center;   
  
}
.rovaniemi-feature-service-item-container-text-header-price-container {
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  transition: 300ms all ease-in;
}
.rovaniemi-feature-service-item-container-text-header-price-reduced {
  color: #274E5B;
  font-family: 'Montserrat', sans-serif;  
  white-space: nowrap;
  font-size: 1.953rem;
  text-align: center;    

  margin: 0;
}
.rovaniemi-feature-service-item-container-text-header-price-normal {
  color: #274E5B;
  font-family: 'Montserrat', sans-serif;  
  font-size: 0.753rem;
  text-align: center;   
  
  margin: 0;
}
.spinner-border {
  width: 1rem !important;
  height: 1rem !important;
}
.rovaniemi-feature-service-item-container-text-header-price-discount {
  color: #274E5B;
  font-size: 0.753rem;
  margin: 0;
}
.rovaniemi-feature-service-item-container-text-header-price:hover {
  background-color: #274E5B;
  color: rgb(98, 196, 228);


}
.rovaniemi-feature-service-item-container-text-sub {
  font-family: 'Open Sans', sans-serif;
  color: #274E5B;
  font-size: 0.9rem;
  text-align: center; 
 

}


.rovaniemi-feature-service-item-container-text-sub-sub {
  margin-bottom: 10px;
  font-weight: 300;
  font-family: 'Montserrat', sans-serif;
}

.rovaniemi-test-container-text-header-service {
  font-family: 'Open Sans', sans-serif;
  text-align: center; 
  
}

.rovaniemi-more-container {
  height: 40vh;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.noUi-handle {
  right: -12px !important;
}

.top-feedbacks {
  display: flex;
  flex-direction: row;
}

.rovaniemi-contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 


}
.rovaniemi-feature-service-item-container-text-header-price-reduced-from {
  font-size: 12px;
  color: #274E5B;
}
.rovaniemi-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: 'center';
  padding: 0px 0px 50px 0;
}
.rovaniemi-header-text {
  color: #274E5B;
  font-family: 'Montserrat', sans-serif;
}
.rovaniemi-header-line {
  background-color: rgb(98, 196, 228);
  height: 5px; 
  width: 100px;
}
.rovaniemi-contact-information-container {
  display: flex;
  flex-direction: column;
  width: 50vw;
  justify-content: center;
  align-items: center;
}
.rovaniemi-contact-information-container-sub {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin-top: 20px;
}
.rovaniemi-contact-information-item-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2px;
}
.rovaniemi-contact-information-item-label-text {
  color: #434343;
  font-family: 'Montserrat', sans-serif;
}
.rovaniemi-contact-information-item-input-field {
  background: #EFEFEF !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  border-radius: 0;
  height: 46px !important;
  padding: 0 15px;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
}
.rovaniemi-contact-information-item-label-text:after { content:"*"; margin-top: -15px; font-size: 12px; }

.rovaniemi-contact-information-item-service-select{
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 300 !important;
}
.rovaniemi-contact-information-submit-button-container {
  border: 3px rgb(98, 196, 228) solid;
  padding: 10px 40px 10px 40px;
  transition: 300ms all ease-in;
  color: rgb(98, 196, 228);
  font-family: 'Montserrat', sans-serif;
  background-color: #fff;
  cursor: pointer;
}

.rovaniemi-contact-information-submit-button-container:hover {
  background-color: rgb(98, 196, 228);
  color: #fff;
}
.rovaniemi-contact-information-submit-button-container.disabled {
  background-color: #999;
  border: 2px solid #777;
  cursor: not-allowed;
  color: #fff;
}
.custom-radio .custom-control-input:checked~.custom-control-label::before, .custom-control-input:checked~.custom-control-label::before {
  background-color: rgb(98, 196, 228) !important;
}
.rovaniemi-contact-information-agree-text {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 300 !important;
  color: #434343;
}
.rovaniemi-contact-information-error-container {
  width: 100%;
  padding-left: 30px;
}
.rovaniemi-contact-information-error-text {
  color: red;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 30px rgba(255,255,255, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
    box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 30px rgba(255,255,255, 0);
      box-shadow: 0 0 0 30px rgba(255,255,255, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
      box-shadow: 0 0 0 0 rgba(255,255,255, 0);
  }
}


@media only screen and (max-width: 600px) {
  .rovaniemi-top-container {
    height: auto;  
    background-size: cover;
  }
  .rovaniemi-top-container-phone-text-container {
    flex: 1;
    align-items: center;
  }
  .rovaniemi-top-container-phone-text-sub {
    text-align: center;
    font-size: 25px;
  }
  .rovaniemi-top-container-phone-container {
    display: none;
  }
  .rovaniemi-service-container {
    height: auto;
  }

  .rovaniemi-buttons-container {
    flex-direction: column;
    
  }
  .rovaniemi-feature-container-call {
    flex-direction: column;
  }
  .rovaniemi-beta-login-or-text-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .rovaniemi-top-container-phone-text-header {
    font-size: 20px;
    text-align: center;
    margin: 0;
    width: 100%;
  }
  .rovaniemi-feature-container-call-text {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .rovaniemi-service-items-container {
    flex-direction: column;
    max-width: 100%;
  }
  .rovaniemi-top-container-you-text {
    font-size: 18px;
    width: 100%;
    text-align: center;
    margin-top: 50px;
  }
  .rovaniemi-feature-container {
    flex-direction: column-reverse;
    height: auto;
    padding-top: 10vh;
    padding-bottom: 10vh;
    margin-top: 0;
    
  }
  .rovaniemi-feature-container.dark {
    flex-direction: column;
  }
  .rovaniemi-feature-container-workspace-text-container, .rovaniemi-feature-container-workspace-text-container-opposite {
    flex: 1;
    justify-content: center;
    align-items: center;
  }
  .rovaniemi-feature-container-workspace-text-header, .rovaniemi-feature-container-workspace-text-sub {
    width: 90%;
  }
  .rovaniemi-feature-service-item-container {
    width: 90%;
  }
  .rovaniemi-feature-container-workspace-image {
    margin: 0;
    margin-bottom: 5vh;
  }

  .rovaniemi-feedback-container-text-header {
    font-size: 28px;
    text-align: left;
    margin: 0;
    width: 70%;
  }


  .rovaniemi-plans-container.dark {
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .rovaniemi-plans-plans-container {
    flex-direction: column;
    
  }
  .rovaniemi-plans-plan-container {
    width: 80vw;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 30px;
    color: #434343;
    border-radius: 10px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,.1), 0 0.9375rem 1.40625rem rgba(90,97,105,.1), 0 0.25rem 0.53125rem rgba(90,97,105,.12), 0 0.125rem 0.1875rem rgba(90,97,105,.1);
  }

  .rovaniemi-plans-plan-container.recommended {
    padding-top: 20px;
    padding-bottom: 20px;    
  }

  .rovaniemi-plans-header-container  {
    padding-left: 10vw;
    padding-right: 10vw;
  }
  .rovaniemi-test-container-text-header {
    font-size: 18px;
    width: 100%;
  }
  .rovaniemi-test-container-use {
    flex-direction: column;
    text-align: center;
    margin: 0;
  }
  .rovaniemi-test-container-sliders {
    padding-left: 0;
  }
  .rovaniemi-test-container-sum {
    margin-top: 50px;
  }
  .rovaniemi-test-container-sum-text {
    font-size: 2.9rem;    
  }
  .rovaniemi-test-container {
    padding: 0;
  }
  .rovaniemi-feature-service-item-container-text-header{
    font-size: 1.753rem;
  }

  .rovaniemi-feedback-container-text-header {
    width: 90%;
    font-size: 1.253rem;
  }
  .rovaniemi-feedbacks-container {
    margin-top: 10px;
  }
  .rovaniemi-feedback-container {
    margin-top: 10px;
    padding: 10px;
  }
  .rovaniemi-feedback-avatar {
    margin: 0;
    margin-right: 10px;
    min-width: 50px;

  }
  .rovaniemi-feedback-avatar-right {
    margin: 0;
    margin-left: 10px;
    min-width: 50px;
  }

  .rovaniemi-feedback-avatar-second {
    margin: 0;
    margin-right: 10px;
    min-width: 50px;
  }
  .rovaniemi-more-container {
    margin-top: 50px;
    padding: 10px; 
  
  }
  .rovaniemi-feature-container-call {
 
  }
  .rovaniemi-contact-information-container  {
    width: 100vw;
  }
  .rovaniemi-contact-information-container-sub{
    flex-direction: column;
    margin-top: 0px;
  }
  .rovaniemi-contact-information-item-container, .rovaniemi-contact-information-item-container-services {
    margin-top: 20px;
  }
  .rovaniemi-contact-information-submit-button-container  {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rovaniemi-header-container {
    padding-top: 20px;
  }
  .rovaniemi-feature-service-item-container-text-header-price {
    font-size: 20px;
    padding: 10px 10px 10px 10px;
  }
}
