.card-button-container {
  display: flex;
  flex: 1;
  justify-content: center;
}
.btn-rounded-container{
  width:100%;
  color: #fff !important;
}
.cleanings-dateranhe-picker {
  width: 30%;
}
.cleanings-dateranhe-picker-header-text {
  color: #999;
  font-family: 'Open Sans', sans-serif;  
  font-size: 12px;
}
.cleanings-filters-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.cleanings-filters-status-container {
  margin-left: 30px;
}
.cleaning-not-done-header {
  color: #999;
  font-family: 'Open Sans', sans-serif;  
  font-size: 12px;
}
table {
  font-family: 'Open Sans', sans-serif;  
}
table th {
  font-family: 'Montserrat', sans-serif;  
  font-size: 12px;
  color: #777;
}
.cleanings-status-items-container {
  display: flex;
  flex-direction: row;
  
}
.cleanings-status-item-container {
  border: 1px solid #c9c9c9;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  margin: 10px 5px;
  color: #888;
  cursor: pointer;
}
.cleanings-status-item-container-text {
  font-size: 12px;
}
.cleamings-new-cleaning-container {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.cleanings-status-item-container.selected {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
}
.cleamings-new-cleaning-container-mobile {
  display: none;
}
.cleanings-mobile-view {
  display: none !important;
}

@media only screen and (max-width: 600px) {
  .card-button-container {
    display: flex;
    flex: 1;
    justify-content: center;
  }
  .cleanings-table-desktop {
    display: none;
  }
  .btn-rounded-container{
    margin: 20px;
    width: 200px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px !important;
    cursor: pointer;      
  }
  .cleanings-filters-container {
    flex-direction: column;
  }
  .cleanings-filters-status-container {
    margin-top: 20px;
    width: 100%;
    margin-left: 0;
  }
  .cleanings-status-items-container {
    flex-wrap: wrap;    
    width: 100%;
  }
  .cleanings-dateranhe-picker {
    width: 100%;
  }
  .cleamings-new-cleaning-container {
    justify-content: center;
    margin-top: 30px;
    display: none;
  }
  .cleanings-dateranhe-picker .DateRangePickerInput_arrow {
    width: 10px;
  }
  .cleanings-dateranhe-picker .DateInput {
    width: 45%;
  }
  .cleanings-status-item-container  {
    margin: 5px;
  }
  .cleamings-new-cleaning-container-button {
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    
  }
  .cleamings-new-cleaning-container-mobile {
    display: inline;
    background: red;
  }
  .cleanings-mobile-view {
    display: flex !important;
  }
}